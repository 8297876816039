<template>
  <div class="page-content">
    <div class="container">
      <div class="list-employee red-theme">
        <div class="row">
          <div class="col-12 col-md-8">
            <h3 class="list-employee__title">Сотрудники компании {{ companyName }}</h3>
            <p class="list-employee__desc">
              Редактирование данные сотрудников,
              отключить от работы или полность удалить профиль
            </p>
          </div>
          <div class="col-12 col-md-4 text-right mb-3">
            <router-link :to="{ name: 'ASSISTANCE_PROVIDE_COMPANY_EMPLOYEE_SCHEDULE'}" class="add-employee__schedule">
             <p>График сотрудников</p>
            </router-link>
          </div>
        </div>
        <template v-if="loading">

          <v-progress-linear indeterminate></v-progress-linear>

        </template>

        <template v-else-if="!totalCountItems">
          <p class="list-employee__desc">В вашей компании нет сотрудников.</p>
        </template>

        <template v-else>
          <ul class="list-employees">
            <EmployeeListItem
              v-for="(employee, i) in employeeList"
              @changeStatus="switchIsCompanyHelperMode"
              @remove="removeEmployee(i)"
              @edit="editServiceEmployee(i)"
              :key="i"
              :employee="employee"
            ></EmployeeListItem>
          </ul>
        </template>

        <template v-if="totalCountPages">
          <Pagination
            v-model="page"
            :length="totalCountPages"
          ></Pagination>
        </template>

        <div class="add-employee-wrapp">
          <router-link :to="{ name: 'ASSISTANCE_PROVIDE_COMPANY_ADD_EMPLOYEE'}" class="add-employee__img">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'add-employee__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M14.126 9.165h-4.96v4.96c0 .645-.525 1.165-1.165 1.165a1.17 1.17 0 01-1.165-1.165v-4.96h-4.96A1.166 1.166 0 01.71 8c0-.64.525-1.165 1.165-1.165h4.96v-4.96C6.836 1.23 7.36.71 8 .71s1.165.525 1.165 1.165v4.96h4.96a1.166 1.166 0 010 2.33z" fill="#FAFAFA"/></svg>
          </router-link>
          <p>Добавить сотрудника</p>
        </div>
        <div class="btn-wrapp">
          <router-link
            :to="{ name: $routeName.ASSISTANCE_PROVIDE_COMPANY_APPLICATIONS }"
            class="btn btn--border btn--fs"
          >Вернуться к заявкам</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EmployeeListItem from "@component/Module/Employee/EmployeeListItem";
import { mapState, mapGetters, mapActions } from 'vuex';
import Pagination from "@component/UI/Pagination/Pagination";

export default {
  name: "Employees",
  components: {
    Pagination,
    EmployeeListItem
  },
  data() {
    return {
      employeeList: [],
      page: 1,
      pageSize: 10,
      totalCountPages: null,
      totalCountItems: null,
      loading: false
    };
  },
  computed: {
    ...mapGetters('user', ['company']),
    companyName() {
      return this.company.name;
    },
  },
  watch: {
    page() {
      this.getEmployeeList();
    }
  },
  async mounted() {
    await this.getEmployeeList();
  },
  methods: {
    ...mapActions('user', ['updateAnotherUserProfileData']),
    editServiceEmployee(i) {
      const {
        to_user: {
          id: userId,
          company_service: {
            id: serviceId,
            category: {
              id: category
            },
            responsibility: {
              id: responsibility
            }
          }
        }
      } = this.employeeList[i];
      this.$modal.show('ServiceProvide',
        {
          title: 'Редактировать услугу',
          text: 'Вы можете внести изменения',
          data: {
            category: { id: category },
            responsibility: { id: responsibility }
          }
        },
        {
          submit: (formData) => updateEmployeeService(formData)
        }
      );

      const updateEmployeeService = (formData) => {
        RequestManager.Invite.updateEmployeeService(userId, serviceId, formData)
          .then(() => {
            this.$modal.hide('ServiceProvide');
            this.getEmployeeList();
          });
      };
    },
    removeEmployee(i) {
      this.$modal.show('Confirm', {
        title: 'Подтвердите удаление сотрудника'
      })
        .then(res => {
          if (!res) return;
          RequestManager.Invite.removeEmployee(this.employeeList[i].id)
            .then(() => this.getEmployeeList());
        });
    },
    async switchIsCompanyHelperMode({ value, user, done}) {
      await this.updateAnotherUserProfileData({
        id: user,
        user: {
          is_company_helper_mode: value
        }
      })
        .finally(() => done());
    },
    async getEmployeeList() {
      this.loading = true;
      await RequestManager.Invite.getEmployeeList({
        page: this.page,
        page_size: this.pageSize
      })
        .then(({ results, count }) => {
          this.totalCountItems = count;
          this.employeeList = results;
          this.totalCountPages = Math.ceil(count / this.pageSize);
        })
        .finally(() => { this.loading = false;});

    }
  }
};
</script>

<style lang="scss" scoped>

.list-employees {
  margin-top: 15px;
}

.list-employee {
  background: #FFFFFF;
  border: 1px solid rgba(118, 118, 128, 0.12);
  border-radius: 10px;
  padding: 47px 26px;
}

.list-employee__title {
  font-size: 25px;
  color: var(--color-prime);
  margin-bottom: 12px;
}

.list-employee__desc {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 27px;
}

.btn-wrapp {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  @include respond-to('md') {
    flex-wrap: nowrap;
    margin-top: 40px;
  }
}

.btn {
  max-width: 343px;
  margin-bottom: 15px;
}

.add-employee__img {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 74px;
  height: 74px;
  border-radius: 50%;
  background-color: #F2F2F7;
  overflow: hidden;
  cursor: pointer;
  margin: 0 15px 0 10px;
}

.add-employee__icon {
  position: relative;
  width: 25px;
  height: auto;
  z-index: 2;
  path {
    fill: var(--color-prime);
    transition: fill .2s ease-in-out;
  }
}

.add-employee:hover .add-employee__icon path {
  fill: var(--color-prime-active);
}

.add-employee-wrapp {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.text-right {
  text-align: left;
  @include respond-to('md') {
    text-align: right;
  }
}

.add-employee__schedule {
  font-size: 18px;
  display: inline-block;
  color: #757579;
  border-bottom: 1px solid #757579;
}

</style>
