<template>
  <li
    :class="['list-employees__item', { 'list-employees__item--disabled': !isJoined }]"
  >
    <div class="employees-left">
      <div class="employees-avatar">
        <img
          v-if="!photo"
          :src="require('@img/user-avatar.svg')"
          alt=""
        >
        <img
          v-else
          :src="photo"
          alt=""
        >
      </div>
      <div class="employees-content">
        <span class="employee-name">
          <template v-if="employee.to_user">
            {{ employee.to_user.first_name }} {{ employee.to_user.last_name }}
          </template>
          <template v-else>
            {{ employee.mobile }}
          </template>
        </span>
        <span
          :class="[
            'employee-status',
            { 'employee-status--active': isJoined }
          ]">{{ status }}</span>
        <p
          v-if="responsibility"
          class="employee-desc"
        >{{ responsibility }}</p>
      </div>
    </div>
    <div class="employees-right">
      <template v-if="employee.to_user">
        <v-switch
          class="employees-switch"
          v-model="isCompanyHelperMode"
          :input-value="employee.to_user.is_company_helper_mode"
          active-color="#35C759"
          inactive-color="#E8E9EB"
          :loading="loading"
          :disabled="loading || !isJoined"
        ></v-switch>
      </template>
      <!--  TODO:-->
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'employees-right__elem employees-edit-service'" @click="$emit('edit')" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 14.25L11.063 3.187l3.75 3.75L3.75 18H0v-3.75zM17.719 4.031L15.89 5.86l-3.75-3.75L13.969.281A.954.954 0 0114.672 0c.281 0 .515.094.703.281l2.344 2.344a.954.954 0 01.281.703.954.954 0 01-.281.703z" fill="#FF9500"/></svg>

      <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'employees-right__elem employees-remove'" @click="$emit('remove')" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.984 15.984v-12h12v12c0 .563-.203 1.047-.609 1.454a1.852 1.852 0 01-1.36.562H3a2.069 2.069 0 01-1.453-.563 2.069 2.069 0 01-.563-1.453zm13.032-15V3H0V.984h3.516L4.5 0h5.016l.984.984h3.516z" fill="#FF3431"/></svg>

    </div>
  </li>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "EmployeeListItem",
  props: {
    employee: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapGetters('enums', ['services']),
    isJoined() {
      return this.$ENUM.USER_INVITE_STATUSES.JOINED.KEY === this.employee.status;
    },
    isCompanyHelperMode: {
      get() {
        return this.employee?.to_user?.is_company_helper_mode;
      },
      set(value) {
        new Promise((resolve) => {
          this.loading = true;
          this.$emit('changeStatus', { value, user: this.employee.to_user.id, done: resolve });
        }).finally(() => this.loading = false);
      },
    },
    status() {
      let statuses = Object.entries(this.$ENUM.USER_INVITE_STATUSES);
      return statuses.find(([, { KEY } ]) => KEY === this.employee.status)[1].VALUE;
    },
    responsibility() {
      let { responsibility, to_user } = this.employee;
      if (to_user) {
        responsibility = to_user?.company_service?.responsibility?.id ?? responsibility;
      }
      let service = this.services.find(service => service.id === responsibility);
      if (service) {
        return service.name;
      }
      return '';
    },
    photo() {
      return this.employee?.to_user?.photo;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-progress-circular--indeterminate {
    .v-progress-circular__overlay {
      stroke: var(--color-prime);
    }
  }
}

.list-employees__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background: #FFFFFF;
  border: 1px solid rgba(118, 118, 128, 0.2);
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 20px;
}

.employees-left {
  display: flex;
  align-items: center;
  .list-employees__item--disabled & {
    filter: grayscale(1);
  }
}

.employees-avatar {
  position: relative;
  width: 74px;
  height: 74px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
  margin-right: 15px;
  img {
    width: 74px;
    height: 74px;
    overflow: hidden;
    border-radius: 50%;
    object-fit: cover;
  }
}

.employee-desc {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.4);
  margin-top: 5px;
}

.employee-name {
  font-weight: 600;
  font-size: 15px;
}

.employees-edit-service,
.employees-switch {
  .list-employees__item--disabled & {
    filter: grayscale(1);
    pointer-events: none;
  }
}
.employees-remove {
  cursor: pointer;
}

.employee-status {
  position: relative;
  font-size: 15px;
  color: #B3B3B8;
  opacity: 0.9;
  padding-left: 10px;
  margin-left: 15px;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #757579;
    opacity: 0.9;
  }
  //TODO
  &--active {
    color: #35C759;
    &::before {
      background: #35C759;
    }
  }
}

//TODO
.list-employees__item.active .employee-status {
  color: #35C759;
}

.employees-right {
  display: flex;
  align-items: center;
  margin: 20px 10px 0 0;
  @include respond-to('md') {
    margin-top: 0;
  }
}

// TODO:
.employees-right__elem {
  margin-left: 15px;
}

.employees-avatar--not {
  img {
    display: none;
  }
}

.employees-avatar--not:before {
  content: "";
  width: 74px;
  height: 74px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  overflow: hidden;
  background: #F2F2F7;
  z-index: 2;
}

.list-employees__item--not  .employees-left {
  opacity: .6;
}

.list-employees__item--not {
  path {
    fill: #EDEDF4;
  }
}
</style>
